import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Category, Props as CategoryProps } from './Category'

export interface Props {
  claim?: string
  categories: CategoryProps[]
}

export const GalleryCategories = memo(function GalleryCategories({
  claim,
  categories,
}: Props) {
  if (!categories) {
    return null
  }

  return (
    <Container>
      {claim ? <Claim>{claim}</Claim> : null}
      <Categories row wrap>
        {categories.map((item, index) => (
          <Category key={index} {...item} />
        ))}
      </Categories>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem auto 7.5rem;

  @media (max-width: 1023px) {
    margin: 10rem auto 5rem;
  }
`

const Claim = styled.h1`
  color: ${({ theme }) => theme.colors.variants.secondaryDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  line-height: 2.9375rem;
  margin-bottom: 6.1875rem;
  text-align: center;
  text-transform: uppercase;
  &:before {
    content: '';
    display: block;
    width: 0.125rem;
    height: 3.75rem;
    background: ${({ theme }) => theme.colors.variants.primaryLight3};
    margin: 0 auto 3rem;
  }

  @media (max-width: 1023px) {
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin-bottom: 3.75rem;
    &:before {
      height: 3rem;
      margin-bottom: 1.875rem;
    }
  }
`

const Categories = styled(FlexBox)``
